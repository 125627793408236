
import { Component, Vue } from 'vue-property-decorator'
import { Area } from '@/types/common.d'
import { passwordNot, email, phone } from '@/utils/validate'
import { ElForm } from 'element-ui/types/form'
import { Info } from '@/types/customer'

@Component({
  name: 'CutomerAdd'
})
export default class extends Vue {
  private title = '新增客户'

  private info: Info = {
    customerName: '',
    customerType: '',
    remark: '',
    province: '',
    city: '',
    area: '',
    address: '',
    validDate: '',
    contactList: [],
    adminAccount: '',
    adminPassword: '',
    versionId: '',
    isImgHtml: ''
  }

  private rules = {
    customerName: [{ required: true, message: '请输入客户名称', trigger: ['blur', 'change'] }],
    customerType: [{ required: true, message: '请选择客户类型', trigger: ['change'] }],
    contactList: [{ required: true, message: '请添加联系人', trigger: ['change'] }],
    name: [{ required: true, message: '请输入姓名', trigger: ['blur', 'change'] }],
    department: [{ required: true, message: '请输入部门', trigger: ['blur', 'change'] }],
    post: [{ required: true, message: '请输入职位', trigger: ['blur', 'change'] }],
    telephone: [{ required: true, message: '请输入联系号码', trigger: ['blur', 'change'] }, { validator: phone, trigger: ['blur', 'change'] }],
    mail: [{ required: true, message: '请输入邮箱', trigger: ['blur', 'change'] }, { validator: email, trigger: ['blur', 'change'] }],
    adminAccount: [{ required: true, message: '请输入系统账号', trigger: ['blur', 'change'] }],
    adminPassword: [{ required: true, message: '请输入系统密码', trigger: ['blur', 'change'] }, { validator: passwordNot, trigger: ['blur', 'change'] }]
  }

  private submitShow = false

  private options: Area[] = []

  private position: string[] = []

  private menuList = []

  private forbid = false
  created () {
    this.getRegions()
    this.addContact()
    this.getMenuList()
    if (this.$route.params.id) {
      this.getData(this.$route.params.id)
      this.title = '编辑客户'
    }
  }

  // 查询详情
  getData (id: string) {
    this.$axios.get<Info>(this.$apis.customer.customerDetails, { customerId: id })
      .then((res) => {
        this.position = [res.province, res.city, res.area]
        this.info = {
          customerName: res.customerName,
          customerType: res.customerType,
          remark: res.remark,
          province: res.province,
          city: res.city,
          area: res.area,
          address: res.address,
          validDate: res.validDate,
          contactList: [],
          adminAccount: res.adminAccount,
          adminPassword: res.adminPassword,
          versionId: res.versionId,
          customerId: res.customerId,
          isImgHtml: res.isImgHtml
        }
        this.forbid = res.customerStatus === '0'
        if (res.contactList) {
          res.contactList.map((item) => {
            this.info.contactList.push(Object.assign({}, item, { isEdit: false }))
          })
        } else {
          this.addContact()
        }
      })
  }

  // 查询版本列表
  getMenuList () {
    this.$axios.get(this.$apis.version.versionList)
      .then((res) => {
        this.menuList = res.list || []
      })
  }

  // 保存
  onSubmit () {
    (this.$refs.info as ElForm).validate((valid) => {
      if (valid) {
        this.submitShow = true
        this.info.contactList.forEach(item => {
          delete item.isEdit
        })
        const url = this.$route.params.id ? this.$apis.customer.customerUpdate : this.$apis.customer.customerAdd
        this.$axios.post(url, this.info)
          .then(() => {
            this.submitShow = false
            this.$message({
              message: '保存成功',
              type: 'success'
            })
            this.$router.push({ name: 'customerList' })
          })
          .catch(err => {
            this.submitShow = false
            this.$message.error(err)
          })
      }
    })
  }

  getRegions () {
    this.$axios.get<Area[]>(this.$apis.common.getRegion).then(res => {
      this.options = res
    })
  }

  // 删除联系人
  removeContact (index: number) {
    this.info.contactList.splice(index, 1)
  }

  addContact () {
    this.info.contactList.push({
      name: '',
      department: '',
      post: '',
      telephone: '',
      mail: '',
      isEdit: true
    })
  }

  // 省市区变化
  positionChange (value: string[]) {
    this.info.province = value[0] || ''
    this.info.city = value[1] || ''
    this.info.area = value[2] || ''
  }

  // 联系人保存
  save (index: number) {
    let valid = true
    const arr = [`contactList[${index}].name`, `contactList[${index}].department`, `contactList[${index}].post`, `contactList[${index}].telephone`, `contactList[${index}].mail`];
    // 判断联系人是否通过效验
    (this.$refs.info as ElForm).validateField(arr, (errorMessage) => {
      if (errorMessage) {
        valid = false
      }
    })
    if (valid) {
      this.info.contactList[index].isEdit = false
    }
  }
}
